/*
  Mi Lote API end ppints
*/
const API_END_POINT_LOG_IN = '/auth/login';
const API_END_POINT_SIGN_UP = '/auth/register';
const API_END_POINT_GET_USER = '/auth/';
const API_END_POINT_GET_FIELDS_META_AND_GEO_DATA =
  '/field/meta_geo_status/by_user';
const API_END_POINT_GET_WEATHER = '/weather';
const API_END_POINT_GET_CROPS = '/generic/crop';
const API_END_POINT_FARMS = '/field/field_owner';
const API_END_POINT_FIELD_BRAND = '/generic/brand';
const API_END_POINT_FIELD_GENOTYPE = '/generic/genotype';
const API_END_POINT_GET_ACTIVITIES = '/activity/activities';
const API_END_POINT_PLANNING = '/v2/planification';
const API_END_POINT_PLANNING_BY_STATUS = '/v2/planification/by_status';
const API_END_POINT_PLANIFICATION_GET_COSTS = '/v2/planification/get_costs';
const API_END_POINT_PLANNING_GET_COST = 'v2/planification/get_costs';
const API_END_POINT_GET_SEASONS = '/v1/season/';
const API_END_POINT_GET_YIELD_PREDICTION = '/v1/analytics/yield_prediction';
const API_END_POINT_GET_EVI_AVG = '/v1/analytics/evi_avg';
const API_END_POINT_GET_CROP_PERFORMANCE = '/v1/analytics/crop_performance';
const API_END_POINT_GET_EVI_LOCATION = '/v1/analytics/evi_location';
const API_END_POINT_CREATE_FIELD_STATUS_BY_PRODUCTION = '/field/status';
const API_END_POINT_GET_ALLOWED_COUNTRIES = '/assets/country/available';
const API_END_POINT_ACTIVITY_FORM_ASSETS = '/assets/activity';
const API_END_POINT_GET_NOTES = '/v1/field_notes/by_field/';
const API_END_POINT_POST_NOTES = '/v1/field_notes/';
const API_END_POINT_IMAGES = '/v1/field_notes/images';
const API_END_POINT_GET_IMAGES = '/v1/field_notes/images/list/';
const API_END_POINT_GET_TYPE_NOTES = '/v1/field_notes/types';
const API_END_POINT_GET_TYPE_NOTE_DETAILS = '/v1/field_notes/details/';
const API_END_POINT_META_DATA = '/field/meta_data';
const API_END_POINT_GEO_DATA = '/field/geo_data';
const API_END_POINT_LAYERS = '/field/layer/';
const API_END_POINT_CATALOG = '/sentinel/catalog';
const API_END_POINT_SENTINEL = '/sentinel/images';
const API_END_POINT_SOIL = '/field/layer/soil';
const API_END_POINT_USER_UPDATE_PASSWORD = 'auth/change-password';
const API_END_POINT_USER_UPDATE_EMAIL = '/auth/change-mail';
const API_END_POINT_USER_DATA_UPDATE = '/auth/update';
const API_END_POINT_CONFIRM_EMAIL_CHANGE = '/auth/confirm-change-email';
const API_END_POINT_OVERSEEDING_COVERAGE = '/overseeding_coverage/v2/list';
const API_END_POINT_PLANNING_PDF_DOWNLOAD = '/v2/planification/pdf_download';
const API_END_POINT_GOOGLE_LOCATION = '/assets/google-maps';
const API_END_POINT_CREATE_OVERSEEDING_COVERAGE =
  '/overseeding_coverage/v2/create';
const API_END_POINT_UPDATE_OVERSEEDING_COVERAGE = '/overseeding_coverage/v2/';
const API_END_POINT_RECOVER_PASSWORD = '/auth/password_reset/';
const API_END_POINT_ACTIVATE_ACCOUNT = 'auth/activate';
const API_END_POINT_TUTORIALS = 'auth/tutorial';
const API_END_POINT_GET_BLUEBIRD_PREDICTORS = '/bluebird/predictors/';
const API_END_POINT_GET_STATIONS = '/weather_station/';
const API_END_POINT_S4_TEST = '/s4/test_s4/';
const API_END_POINT_PAYMENT_CHECKOUT = '/payment/checkout';
const API_END_POINT_PAYMENT_PRICING_PLANS = '/payment/pricing-plans';
const API_END_POINT_PAYMENT_TRIAL = '/payment/trial';
const API_END_POINT_GET_PAYMENT_TRIAL_STATUS = '/payment/has-trial/';
const API_END_POINT_ANALITYCS_PDF_DOWNLOAD = '/v1/analytics/pdf_download';
const API_END_POINT_UPLOAD_BLUEBIRD_IMAGES = '/bluebird/upload-image/';
const API_END_POINT_BLUEBIRD_CREATE_PREDICTOR = '/bluebird/create-predictor/';
const API_END_POINT_GET_PEAT_IMAGES = 'peat/peat-images';
const API_END_POINT_GET_BRECHAS = '/field/treatment_types';
const API_END_POINT_PEAT_UPLOAD_IMAGES = '/peat/upload-image/';
const API_END_POINT_BLUEBIRD_PREDICTORS = '/bluebird/predictors';
const API_END_POINT_PUBLIC_NOTES = '/v1/field_notes/public_notes';
const API_END_POINT_GET_PUBLIC_NOTES_STATUS =
  '/v1/field_notes/public_notes/status';
const API_END_POINT_CROP_LOG = '/croplog';
const API_END_POINT_FEATURES = '/config/features';
const API_END_POINT_GET_NOTIFICATION = '/notification';
const API_END_POINT_NOTIFICATION_READ = '/notification/read';
const API_END_POINT_PLANET = '/planet/get-aoi';
const API_END_POINT_LABELS = '/assets/labels';
const API_END_POINT_GET_ACTIVITY = '/activity/activity';
const API_END_POINT_GET_ACTIVITIES_TYPE = '/activity';
const API_END_POINT_CREATE_ACTIVITY = '/activity/create-activity';
const API_END_POINT_YEAR_TYPE = '/generic/yeartype';
const API_END_POINT_PHENOLOGICAL_STAGES = '/generic/phenological-stages';
const API_END_POINT_PRODUCT_TYPE = '/generic/product-types';
const API_END_POINT_UNITS = '/generic/units';
const API_END_GET_PRODUCTS = '/activity/product';
const API_END_GET_APLICATION_MODE = '/activity/mode-type';
const API_END_GET_APLICATION_MODE_ACTIIVITY = '/activity/mode-type-by-activity';
const API_END_POINT_DELETE_ACTIVITIES = '/activity/activity';
const API_END_POINT_ACTIVE_AMBIENTACION =
  '/field/meta_data/activate_ambientation';
const API_END_POINT_REQUEST_AMBIENTATION_TOKEN = 'onesoil';
const API_END_POINT_GEO_DATA_KML = '/field/geo_data_kml';
const API_END_POINT_FIELD_MULTIPLE_FIELDS = '/field/multiple_fields';
const APU_END_POINT_REPORT_IMAGES = '';
const API_END_POINT_GENERIC_GENOTYPE_EXPLORER = 'generic/genotype/explorer';
const API_END_POINT_CROP_EXPLORER_ANALISIS = '/crop_explorer/analisis/';
const API_END_POINT_CROP_EXPLORER_HISTORICAL = '/crop_explorer/historical/';
const API_END_POINT_ACTIVITY_REPORT = '/activity/report';
const API_END_POINT_CROPLOG_RAIN_IMPORT = '/croplog/rain/import';
const API_END_POINT_AUTH_LOGO_UPLOAD = '/auth/logo/upload/';
const API_END_POINT_AUTH_LOGO = '/auth/logo';
const API_END_PONT_FIELD_META_DATA_AUTHORIZATION_BY_FIELD =
  '/field/meta_data/authorization/by_field';
const API_END_PONT_FIELD_META_DATA_CREATE_AUTHORIZATION =
  'field/meta_data/authorization';
const API_END_PONT_FIELD_META_DATA_DELETE_AUTHORIZATION =
  '/field/meta_data/authorization';
const API_END_PONT_PIONEER_REPORT_BATCH_REPORT_BY_USER =
  '/pioneer_report/batch_report/by_user/'; // +id
const API_END_PONT_PIONEER_REPORT_BATCH_REPORT = '/pioneer_report/batch_report'; // +id DELETE
const API_END_PONT_PIONEER_REPORT_BATCH_REPORT_CREATE =
  '/pioneer_report/batch_report/create';
const API_END_PONT_PIONEER_REPORT_REPRODUCTIVE_REPORT_POINT =
  '/pioneer_report/reproductive_report_point/';
const API_END_PONT_GENERIC_BATCH = '/generic/batch'; // + (id de genotype)
const API_END_PONT_PIONEER_REPORT_PRODUCER_CREATE =
  '/pioneer_report/producer/create';
const API_END_PONT_PIONEER_REPORT_PRODUCER = '/pioneer_report/producer';
const API_END_POINT_PIONEER_REPORT_PRODUCER_BY_USER =
  '/pioneer_report/producer/by_user/';
const API_END_POINT_GENERIC_GENOTYPE_PIONNER_REPORT =
  '/generic/genotype/pioneer_report';
const API_END_POINT_PIONER_REPORT_BATCH_REPORT = '/pioneer_report/batch_report';
const API_END_POINT_GENERIC_SPECIFIC_BATCH = '/generic/specificBatch';
const API_END_POINT_PIONEER_REPORT_PLANIFICATION_PDF =
  '/pioneer_report/planification_report/create/';
const API_END_POINT_PIONNER_REPORT_PLANIFICATION_REPORT =
  '/pioneer_report/planification_report';
const API_END_POINT_PIONNER_OVERSEEDING_COVERAGE_GET_ALL =
  '/pioneer_overseeding_coverage/get_all/';
const API_END_POINT_PIONNER_OVERSEEDING_COVERAGE_CREATE =
  '/pioneer_overseeding_coverage/create';
const API_END_POINT_GET_FIELD_STATUS = '/field/status_by_field_list/';
const API_END_POINT_PIONNER_OVERSEEDING_COVERAGE_DELETE = '';
const API_END_POINT_PIONEER_OVERSEEDING_COVERAGE_GET =
  '/pioneer_overseeding_coverage/get';
const API_END_POINT_PIONEER_OVERSEEDING_COVERAGE_ACTIVATE =
  '/pioneer_overseeding_coverage/activate';
const API_END_POINT_AUTH_LIST_USERS = '/auth/list_users';
const API_END_POINT_AUTH_UPDATE_USER = '/auth/update_user';
const API_END_POINT_SENTINEL_STATISTICS = '/sentinel/statistics';
const API_END_POINT_CROP_EXPLORER_HOURS_BELOW_0 = 'crop_explorer/hours_below_0';
const API_END_POINT_BREVANT_REPORT_PRICE_RELATIONS =
  '/brevant_report/price_relations';
const API_END_POINT_GENERIC_GENOTYPE_DENSITY_RECOMENDATION =
  '/generic/genotype/density_recommendation';
const API_END_POINT_BREVANT_REPORT_CALCULATE_NITROGEN_RECOMENDATION =
  '/brevant_report/calculate_nitrogen_recomendation';
const API_END_POINT_GENOTYPE_DENSITY_RECOMMENDER_HYBRIDS =
  '/generic/genotype/density_recommender_hybrids';
const API_END_POINT_AUTH_ROL = '/auth/rol';
const API_END_POINT_AUTH_USERS_RSM = '/auth/users/rsm';
const API_END_POINT_AUTH_USERS_FSM = '/auth/users/fsm';
const API_END_POINT_AUTH_USERS_FA = '/auth/users/fa';

/*
  Rainviewer API end ppints
*/
const RAINVIEWER_API_END_POINT_TIMESTAMPS = '/public/maps.json';

/*
  Realearth API end ppints
*/
const REALEARTH_API_END_POINT_TIMESTAMPS =
  '/products?products=globalir-rr&allapps=true&proxy=true';

export {
  API_END_POINT_PEAT_UPLOAD_IMAGES,
  API_END_POINT_GET_PEAT_IMAGES,
  API_END_POINT_BLUEBIRD_PREDICTORS,
  API_END_POINT_BLUEBIRD_CREATE_PREDICTOR,
  API_END_POINT_UPLOAD_BLUEBIRD_IMAGES,
  API_END_POINT_GET_BLUEBIRD_PREDICTORS,
  API_END_POINT_USER_DATA_UPDATE,
  API_END_POINT_GOOGLE_LOCATION,
  API_END_POINT_ANALITYCS_PDF_DOWNLOAD,
  API_END_POINT_CONFIRM_EMAIL_CHANGE,
  API_END_POINT_USER_UPDATE_EMAIL,
  API_END_POINT_TUTORIALS,
  API_END_POINT_ACTIVATE_ACCOUNT,
  API_END_POINT_RECOVER_PASSWORD,
  API_END_POINT_PLANNING_BY_STATUS,
  API_END_POINT_PLANNING,
  API_END_POINT_USER_UPDATE_PASSWORD,
  API_END_POINT_SOIL,
  API_END_POINT_SENTINEL,
  API_END_POINT_CATALOG,
  API_END_POINT_LAYERS,
  API_END_POINT_META_DATA,
  API_END_POINT_GEO_DATA,
  API_END_POINT_FARMS,
  API_END_POINT_ACTIVITY_FORM_ASSETS,
  API_END_POINT_CREATE_FIELD_STATUS_BY_PRODUCTION,
  API_END_POINT_LOG_IN,
  API_END_POINT_SIGN_UP,
  API_END_POINT_GET_USER,
  API_END_POINT_GET_FIELDS_META_AND_GEO_DATA,
  API_END_POINT_GET_WEATHER,
  API_END_POINT_GET_CROPS,
  API_END_POINT_GET_ACTIVITIES,
  API_END_POINT_GET_SEASONS,
  API_END_POINT_GET_NOTES,
  API_END_POINT_POST_NOTES,
  API_END_POINT_IMAGES,
  API_END_POINT_GET_IMAGES,
  RAINVIEWER_API_END_POINT_TIMESTAMPS,
  REALEARTH_API_END_POINT_TIMESTAMPS,
  API_END_POINT_GET_YIELD_PREDICTION,
  API_END_POINT_GET_EVI_AVG,
  API_END_POINT_GET_CROP_PERFORMANCE,
  API_END_POINT_GET_EVI_LOCATION,
  API_END_POINT_GET_TYPE_NOTES,
  API_END_POINT_GET_ALLOWED_COUNTRIES,
  API_END_POINT_OVERSEEDING_COVERAGE,
  API_END_POINT_GET_STATIONS,
  API_END_POINT_S4_TEST,
  API_END_POINT_PAYMENT_CHECKOUT,
  API_END_POINT_PAYMENT_PRICING_PLANS,
  API_END_POINT_PAYMENT_TRIAL,
  API_END_POINT_CREATE_OVERSEEDING_COVERAGE,
  API_END_POINT_UPDATE_OVERSEEDING_COVERAGE,
  API_END_POINT_GET_PAYMENT_TRIAL_STATUS,
  API_END_POINT_PLANNING_PDF_DOWNLOAD,
  API_END_POINT_GET_TYPE_NOTE_DETAILS,
  API_END_POINT_PUBLIC_NOTES,
  API_END_POINT_GET_PUBLIC_NOTES_STATUS,
  API_END_POINT_CROP_LOG,
  API_END_POINT_FIELD_BRAND,
  API_END_POINT_FIELD_GENOTYPE,
  API_END_POINT_FEATURES,
  API_END_POINT_GET_NOTIFICATION,
  API_END_POINT_NOTIFICATION_READ,
  API_END_POINT_PLANET,
  API_END_POINT_LABELS,
  API_END_POINT_GET_ACTIVITIES_TYPE,
  API_END_POINT_CREATE_ACTIVITY,
  API_END_POINT_YEAR_TYPE,
  API_END_POINT_PHENOLOGICAL_STAGES,
  API_END_POINT_PRODUCT_TYPE,
  API_END_POINT_UNITS,
  API_END_GET_PRODUCTS,
  API_END_GET_APLICATION_MODE,
  API_END_POINT_DELETE_ACTIVITIES,
  API_END_POINT_GET_ACTIVITY,
  API_END_POINT_ACTIVE_AMBIENTACION,
  API_END_POINT_REQUEST_AMBIENTATION_TOKEN,
  API_END_POINT_GEO_DATA_KML,
  API_END_POINT_FIELD_MULTIPLE_FIELDS,
  APU_END_POINT_REPORT_IMAGES,
  API_END_POINT_GENERIC_GENOTYPE_EXPLORER,
  API_END_POINT_CROP_EXPLORER_ANALISIS,
  API_END_POINT_ACTIVITY_REPORT,
  API_END_POINT_CROPLOG_RAIN_IMPORT,
  API_END_POINT_CROP_EXPLORER_HISTORICAL,
  API_END_POINT_AUTH_LOGO_UPLOAD,
  API_END_POINT_AUTH_LOGO,
  API_END_PONT_FIELD_META_DATA_AUTHORIZATION_BY_FIELD,
  API_END_PONT_FIELD_META_DATA_CREATE_AUTHORIZATION,
  API_END_PONT_FIELD_META_DATA_DELETE_AUTHORIZATION,
  API_END_PONT_PIONEER_REPORT_BATCH_REPORT,
  API_END_PONT_PIONEER_REPORT_BATCH_REPORT_CREATE,
  API_END_PONT_PIONEER_REPORT_REPRODUCTIVE_REPORT_POINT,
  API_END_PONT_GENERIC_BATCH,
  API_END_PONT_PIONEER_REPORT_BATCH_REPORT_BY_USER,
  API_END_PONT_PIONEER_REPORT_PRODUCER_CREATE,
  API_END_POINT_PIONEER_REPORT_PRODUCER_BY_USER,
  API_END_POINT_GENERIC_GENOTYPE_PIONNER_REPORT,
  API_END_PONT_PIONEER_REPORT_PRODUCER,
  API_END_POINT_PIONER_REPORT_BATCH_REPORT,
  API_END_POINT_GENERIC_SPECIFIC_BATCH,
  API_END_POINT_PIONEER_REPORT_PLANIFICATION_PDF,
  API_END_POINT_PIONNER_REPORT_PLANIFICATION_REPORT,
  API_END_POINT_PIONNER_OVERSEEDING_COVERAGE_GET_ALL,
  API_END_POINT_PIONNER_OVERSEEDING_COVERAGE_CREATE,
  API_END_POINT_GET_FIELD_STATUS,
  API_END_POINT_PIONNER_OVERSEEDING_COVERAGE_DELETE,
  API_END_POINT_PIONEER_OVERSEEDING_COVERAGE_ACTIVATE,
  API_END_POINT_PIONEER_OVERSEEDING_COVERAGE_GET,
  API_END_POINT_AUTH_LIST_USERS,
  API_END_POINT_AUTH_UPDATE_USER,
  API_END_POINT_SENTINEL_STATISTICS,
  API_END_POINT_CROP_EXPLORER_HOURS_BELOW_0,
  API_END_POINT_GET_BRECHAS,
  API_END_POINT_PLANIFICATION_GET_COSTS,
  API_END_GET_APLICATION_MODE_ACTIIVITY,
  API_END_POINT_PLANNING_GET_COST,
  API_END_POINT_BREVANT_REPORT_PRICE_RELATIONS,
  API_END_POINT_GENERIC_GENOTYPE_DENSITY_RECOMENDATION,
  API_END_POINT_BREVANT_REPORT_CALCULATE_NITROGEN_RECOMENDATION,
  API_END_POINT_GENOTYPE_DENSITY_RECOMMENDER_HYBRIDS,
  API_END_POINT_AUTH_ROL,
  API_END_POINT_AUTH_USERS_RSM,
  API_END_POINT_AUTH_USERS_FSM,
  API_END_POINT_AUTH_USERS_FA,
};
